/*------------------------------------
  Labels
------------------------------------*/

.form-label-secondary {
  color: $form-label-secondary-color;
}

.form-label-link {
  font-weight: $form-label-font-weight;
  font-size: $form-label-font-size;
  margin-bottom: $form-label-margin-bottom;
}