/*------------------------------------
  Banner
------------------------------------*/

// Half Start
.banner-half-start {
  position: relative;

  &::before {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    width: 100vw;
    height: 150vh;
    z-index: -1;
    content: '';
    background-color: $primary-dark;
  }

  &::after {
    position: absolute;
    top: auto;
    right: -.75rem;
    bottom: 0;
    display: none;
    width: 100vw;
    height: 150vh;
    z-index: -1;
    content: '';
    background-color: $primary-dark;
  }

  @include media-breakpoint-down(md) {
    &::after {
      display: block;
    }
  }
}

// Half End
$banner-half-end-top-offset: 7.5% !default;

.banner-half-end {
  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: $banner-half-end-top-offset;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - #{$banner-half-end-top-offset * 2});
    display: block;
    content: '';
    background: linear-gradient(to right, $gradient-transparent 53%, $gradient-primary-soft 53%);
  }

  @include media-breakpoint-down(md) {
    position: relative;

    &::after {
      background: linear-gradient(to right, $gradient-transparent 0%, $gradient-primary-soft 0%);
    }
  }
}

// Start
.banner-start {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to left, $gradient-transparent 22%, $gradient-primary-soft 22%);
    content: '';
  }

  @include media-breakpoint-down(md) {
    &::after {
      height: 80%;
      background: linear-gradient(to right, $gradient-transparent 0%, $gradient-primary-soft 0%);
    }
  }
}

// End
.banner-end {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to right, $gradient-transparent 22%, $gradient-primary-soft 22%);
    content: '';
  }

  @include media-breakpoint-down(md) {
    &::after {
      height: 80%;
      background: linear-gradient(to right, $gradient-transparent 0%, $gradient-primary-soft 0%);
    }
  }
}

// Bottom End
.banner-bottom-end-n3rem {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    bottom: -3rem;
    left: -3rem;
    width: 100%;
    height: 100%;
    background-color: $gradient-primary-soft;
    content: '';
  }
}
