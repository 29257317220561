.documentation-content {
  --bs-code-color: #0099E3;

  h1 {
    margin-bottom: 3rem
  }
  blockquote {
    border-left: 5px solid #f0f0f0;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    background-color: #f9f9f9;
    border-radius: 0.25rem;
    font-weight: 350;
    font-size: 0.85rem;
    > :last-child {
      margin-bottom: 0;
    }
  }
  code {
    font-variation-settings: "MONO" 1000;
    font-weight: 350;
    font-size: 0.85rem;
  }
}
