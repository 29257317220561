/*------------------------------------
  Transform
------------------------------------*/

.transform-0 {
  transform: translate(0rem, 0rem);
}

.transform-y-5rem {
  transform: translateY(5rem);

  @include media-breakpoint-down(md) {
    transform: translateY(3rem);
  }
}

.rotated-3d-left {
  transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg);
}

.rotated-3d-right {
  transform: perspective(1140px) rotateX(7deg) rotateY(17deg) rotateZ(-4deg);
}