@font-face {
  font-family: "Adelle Mono Flex";
  src: url("../assets/fonts/AdelleMono_var-Upright.woff2");
}

:root {
  --col-primary-30: #{$primary-30};
  --col-primary-bright: #{$primary-bright};
  --col-accent-10: #{$accent-10};
  --col-accent-3-1: #{$accent-3-1};
  --bs-body-color: #{$gray};
  --bs-body-line-height: 1.75;
  --bs-font-monospace: "Adelle Mono Flex", monospace;
  --bs-link-color-rgb: 0, 99, 204;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  > header, > footer {
    flex: 0;
  }
  > main {
    flex: 1
  }
  font-style: normal;
  font-weight: 500;
  font-variation-settings: 'MONO' 0;
  color: var(--bs-body-color);
}

@media(prefers-color-scheme: dark) {
  .favicon path {
    fill: #fff;
  }
}


// Utility classes

.bg-accent-10 {
  background-color: var(--col-accent-10);
}

.bg-primary-30 {
  background-color: var(--col-primary-30);
}

.btn {
  --bs-btn-font-weight: 550;
}

.btn-ghost-primary {
  color: var(--col-accent-3-1);
  &:hover {
    color: $accent-link;
  }
}

.btn-primary {
  --bs-btn-color: #{$dark};
}

.btn-white {
  color: var(--col-accent-3-1);
  &:hover {
    color: $accent-link;
  }
}

.display-3 {
  letter-spacing: -0.01em;
}

@include media-breakpoint-up(sm) {
  .mw-sm-none {
    max-width: none !important;
  }
}

@include media-breakpoint-up(md) {
  .mw-md-none {
    max-width: none !important;
  }
}

.text-sm {
  font-size: $link-sm-font-size;
}

.text-primary-bright {
  color: var(--col-primary-bright);
}

.text-accent-3-1 {
  color: var(--col-accent-3-1);
}

.text-yellow {
  color: $yellow;
}



// Base styles

a {
  text-decoration: underline;
  &.nav-link, &.btn, &.accordion-button {
    text-decoration: none;
  }
}

h6 {
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0.6875rem;;
}


// Components

.accordion-button {
  align-items: flex-start;
  gap: 1rem;
  &::after {
    margin-top: 0.4rem;
  }
}

.code-editor {
  font-variation-settings: "MONO" 1000;
}

.code-editor-highlight {
  color: $primary-bright;
}

.code-editor-line-numbers {
  border-top-left-radius: $code-editor-border-radius;
  border-bottom-left-radius: $code-editor-border-radius;
}

.code-editor-typing-area {
  white-space: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.content-page-body {
  // Make H2, H3 the size of H4, H5
  h2 {
    font-size: calc(1.275rem + 0.3vw);
  }
  @media (min-width: 1200px) {
    h2 {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 1.25rem;
  }

  h2, h3 {
    font-weight: 700;
    margin-top: 2rem;
  }
}

.form-control:focus {
  outline: 2px solid var(--col-accent-3-1);
}

.github-markdown-content {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
    margin: 3rem 0 1rem;
  }
  h3 {
    font-size: 1.25rem;
    margin: 2.5rem 0 1rem;
  }
  h2 + h3 {
    margin-top: 1rem;
  }
}
.list-checked-primary {
  .list-checked-item::before {
    background-image: escape-svg(url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='#{$accent-3-1}'/></svg>"));
  }
}

.main-header {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #130048;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  padding-top: 20px;
  padding-bottom: 20px;
  .nav-link {
    color: #fff;
    opacity: 0.6;
    &.active, &.active:hover {
      color: #fff;
      opacity: 1;
    }
  @include media-breakpoint-up(lg) {
      margin: 0.5rem 0.25rem calc(0.5rem - 2px);
      opacity: 1;
      border-bottom: 2px solid transparent;
      &.active {
        border-bottom: 2px solid #fff;
      }
    }
  }
}

.navbar-expand-lg .navbar-nav .nav-subtitle,
.navbar-expand-lg .navbar-nav .nav-link {
  padding-top: 0.4rem; // Ensure item height is >= 40px
  padding-bottom: 0.4rem; // Ensure item height is >= 40px
}

.navbar-toggler {
  color: #fff;
}

@include media-breakpoint-down(sm) {
  .no-card-xs {
    --bs-card-bg: transparent;
    box-shadow: none;
    > .card-body {
      padding: 0;
    }
    > .card-footer {
      margin-top: 2rem;
      border-radius: var(--bs-card-inner-border-radius);
    }
  }
}

.plan-card {
  > .card-header, > .card-body, > .card-footer {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  .card-body {
    font-size: 0.85rem;
  }
  .list-checked-item {
    padding-left: 1.5rem;
  }
  .card-footer {
    font-size: 0.8rem;
    line-height: 1.6;
  }
}

.shape {
  overflow: hidden;
  --shape-width: 3000px;
  > svg {
    width: var(--shape-width);
    transform: translateX(calc(50vw - var(--shape-width) / 2));
  }
}


// Side nav (documentation)
.nav-vertical {
  .nav-subtitle {
    font-size: 0.85rem;
    padding-bottom: 0.8rem;
    font-weight: 650;
  }
  .nav-item {
    font-size: 0.85rem;
  }
}
