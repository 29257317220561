// user-variables.scss
// Use this to overwrite Bootstrap and Space variables
//


// Example of a variable override to change Space's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: red;
// $font-size-base: 1rem;
@use 'sass:math';

$enable-negative-margins: true;
$font-family-base: 'Adelle Mono Flex', sans-serif;
$font-size-base: 0.9375rem;
$display-font-weight: 400;

$primary: rgba(0, 209, 255);
$primary-30: rgba(178, 241, 255, 1);
$primary-bright: rgba(28, 2, 100, 1);
$accent-10: rgba(229, 250, 255, 1);
$accent-3-1: rgba(0, 153, 227, 1);
$accent-link: rgba(0, 99, 204, 1);
$yellow: rgba(251, 255, 72);
$dark: rgba(19, 0, 72);
$gray: #545259;
$gray-30: #8E8A96;
$gray-70: $gray;

$accordion-icon-color: $accent-link;
$accordion-icon-active-color: $accent-link;

$code-editor-line-numbers-bg-color: $primary-30;
$code-editor-line-numbers-color: $gray-70;

$input-border-color: $gray-30;

$nav-tabs-link-active-color: rgba(43, 51, 140, 1);
$nav-tabs-link-hover-color: mix($nav-tabs-link-active-color, #fff, 60%);

$navbar-bg-color: transparent;
$nav-divider-border-color: rgba(255, 255, 255, 0.15);
$navbar-nav-padding-x: 0;
$navbar-nav-padding-y: 1rem;
$navbar-nav-link-padding-y: 0.5rem;
$navbar-nav-link-padding-x: 0.5rem;

$nav-gray-link-active-color: $accent-link;
$nav-tabs-link-active-border-color: $accent-3-1;
