/*------------------------------------
  Form Check
------------------------------------*/

.form-check-input,
.form-check-label {
  cursor: pointer;
}

// Validation
.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: $form-check-input-border;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $form-check-label-color;
}

// Custom
.form-check-custom {
  .form-check-input {
    &:checked {
      &[type="checkbox"],
      &[type="radio"] {
        background-image: escape-svg($form-check-custom-input-checked-bg-image);
      }
    }
  }
}

// Row Select
.form-check-row-select {
  &.checked {
    background-color: $form-check-selectable-bg-active-color;
  }
}